<script>
export default {
  name: "PromoBlock"
}
</script>

<template>
<div class="common-white-container">
  <div class="image">
    <slot name="imageSlot"></slot>
  </div>
  <div class="text-content">
    <div class="common-mid-block-title">
      <slot name="titleSlot"></slot>
    </div>
    <div class="text-slot">
      <slot name="textSlot"></slot>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.common-white-container {
  padding: 10px;
  width: 360px;
  height: 655px;
}
.common-mid-block-title {
  margin-top: 10px;
  margin-left: 20px;
}
.text-slot {
  color: var(--neutral-800);
  font-weight: 400;
  font-size: 16px;
}
.text-content {
  padding-right: 24px;
}
</style>